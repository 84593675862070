const $ = require('jquery');
//import './function.js';
//import './eccube.js';
import 'jquery-drawer';
import 'jquery-drawer/dist/css/drawer.min.css';
import 'slick-carousel'
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import 'lity';
import 'lity/dist/lity.min.css';
const IScroll = require('iscroll');
const SmoothScroll = require('smooth-scroll');

$(function () {
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // スライダー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '200px',
    // arrows: false,
    // fade: true,
    asNavFor: '.slider-nav',
    prevArrow: "<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
    nextArrow: "<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
        }
      }
    ]
  });
  const slideNum = $('.slider .slick-slide').length;
  $('.slider-nav').slick({
    slidesToShow: slideNum,
    asNavFor: '.slider',
    dots: false,
    centerMode: true,
    arrows: false,
    focusOnSelect: true,
  });

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  // ドロワーメニュー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.drawer').drawer();

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //スムーススクロールJS
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '[data-scroll-header]',
    updateURL: false
  });

  $(window).on('scroll', function () {
    var scroll = $(this).scrollTop();
    var footerPos = $('.footer').offset().top;
    var h = window.innerHeight;
    if (scroll == 0) {
      $('.pagetop').addClass('is-hidden');
    } else {
      $('.pagetop').removeClass('is-hidden');
    }
    if (footerPos <= scroll + h - 70) {
      $('.pagetop').addClass('is-fixed');
    } else {
      $('.pagetop').removeClass('is-fixed');
    }
  });

  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //メガメニュー
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  $('.categoryNav__navlink--hasChild').on('click', function () {
    var target = $(this).next('.megamenu');
    $(this).toggleClass('open');
    if (target.hasClass('megamenu--open')) {
      target.removeClass('megamenu--open');
    } else {
      target.addClass('megamenu--open');
    }
    return false;
  });
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  //サイドバー：サブカテゴリーをホバーで表示
  //ーーーーーーーーーーーーーーーーーーーーーーーーーー
  if (matchMedia('(max-width: 575px)').matches) {

    $('.sideCategoryNav__item').on('click', function (e) {
	  $(this).find('.sideCategoryNav__child').toggleClass('is-visible');
	  e.preventDefault();
      return false;
	});
	$('.sideCategoryNav__child').on('click', function(e){
		e.stopPropagation();
	});

  } else {

    $('.sideCategoryNav__item').on('mouseenter', function () {
      $(this).find('.sideCategoryNav__child').addClass('is-visible');
    }).on('mouseleave', function () {
      $(this).find('.sideCategoryNav__child').removeClass('is-visible');
    });

  }
});

